import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faBandcamp, faBluesky, faCreativeCommons, faMastodon, faTwitch }
from '@fortawesome/free-brands-svg-icons';  // faMastodon, faPatreon
import { faBars, faEnvelope, faFileDownload, faStepForward, faRandom,
         faRssSquare }  // faShareAlt
from '@fortawesome/free-solid-svg-icons';
import lunr from 'lunr';
import socialshares from 'socialshares/dist/socialshares';
import u from 'umbrellajs/umbrella.esm.js';
import './style.css';


library.add(faBandcamp, faBluesky, faCreativeCommons, faMastodon, faTwitch, faBars,
            faEnvelope, faFileDownload, faStepForward, faRandom, faRssSquare);

// Replace any existing <i> tags with <svg> continually as the DOM changes
dom.watch();

let tracks = [];
let playing = 0;
let shuffle = false;
let lunrIndex;

const loadTracks = async () => {
    const data = await fetch('/feed.json').then(res => res.json());
    lunrIndex = lunr(function () {  // Set up search index
        this.field('title', { boost: 10 });
        this.field('tag_words', { boost: 5 });
        this.field('summary');
        this.ref('id');  // Result identifier
        data.items.forEach(track => {
            track.tags = track.content_html.split('Tags: ')[1].split(',');
            track.tag_words = track.tags.reduce(  // Further split multi-word tags
                (acc, tag) => acc.concat(tag.replace(' ', ',').split(',')), []);
            this.add(track);
        });
    });
    return data.items;
};

const search = (query) => {
    // Add wildcard to end of query and return track indices for metadata
    return query.length > 0 && lunrIndex.search(query + ' ' + query + '*').map(
        result => tracks.findIndex(track => track.id === result.ref));
};

const renderResults = (results) => {
    u('#search-results').empty();
    // Show at most ten results, if there are results to show
    results && results.slice(0, 10).forEach(result => {
        u('#search-results').append(
            `<span class="button stack search-result" data-index="${result}">
                 ${tracks[result].title}
             </span>`);
    });
    u('.search-result').handle('click', e => {
        // TODO DRY with regular .track handling?
        u('#search-results').empty();
        const index = parseInt(e.target.dataset.index);
        playing = index;
        play(playing);
    });
};

const play = (index, pushState=true) => {
    index = index % tracks.length;  // Wrap-around if needed
    playing = index;
    const trackID = tracks[index].id;
    const trackURL = '<source src="/' + trackID;
    const trackDate = tracks[index].date_modified.substring(0, 10);
    u('audio').first().pause();  // Maybe not needed?
    u('audio').empty();
    u('.active').removeClass('active');
    // TODO switch back to ogg when/if Archive provides again
    // TODO switch back to files that are hosted by jazzaria.com when build works
    // u('audio').append(trackURL + '.mp3" type="audio/mpeg">');
    u('audio').append('<source src="' + tracks[index].image.slice(0, -4) + '.mp3" type="audio/mpeg">');
    u('audio').first().load();
    u('audio').first().play();  // TODO hook up to URL params?
    u('#_' + trackID).addClass('active');
    // u('#hero-image').attr('src', '/' + trackID + '.jpg');
    u('#hero-image').attr('src', tracks[index].image);
    u('#track-description').html(
        `<p>${tracks[index].summary}</p>${shareButtons()}
         <p><i>Released: ${trackDate}</i></p>`);
    u('#_' + trackID).scroll();

    u('[data-tooltip="Download"]').attr('href', '/' + trackID + '.mp3');
    u('title').html('Jazzaria - ' + tracks[index].title);
    u('meta[name="description"]').attr('content', tracks[index].summary);
    u('meta[name="keywords"]').attr('content', tracks[index].tags);
    if (pushState) {
        window.history.pushState({ id: trackID }, null, '/' + trackID + '/');
    }
    socialshares.mount();
    bole();
};

const setup = async () => {
    tracks = tracks.length ? tracks : await loadTracks();  // TODO import JSON
    tracks.forEach(appendTrack);
    const randTrack = () => Math.floor(Math.random() * tracks.length);
    u('.track').handle('click', e => play(parseInt(e.target.dataset.index)));
    u('audio').on('ended', e => play(shuffle ? randTrack() : playing + 1));
    u('audio').on('play', e => {
        if (!e.target.firstElementChild) {
            // Nothing loaded, set to first track or random if shuffle
            play(shuffle ? randTrack() : 0);
        }
    });
    u('[data-tooltip="Previous"]').handle('click', e => {
        playing--;  // TODO nicer, DRYer with other similar logic, shuffle?
        playing = playing === -1 ? tracks.length - 1 : playing;
        play(playing);
    });
    u('[data-tooltip="Next"]').handle(
        'click', e => play(shuffle ? randTrack() : playing + 1));
    u('[data-tooltip="Shuffle"]').on('change', e => shuffle = e.target.checked);
    u('#search').on('keyup', e => renderResults(search(e.target.value)));
    if (location.pathname !== '/') {
        const trackName = location.pathname.replace(/\//g, '');
        // TODO DRY with onpopstate
        playing = u('#_' + trackName).data('index');
        play(playing);
    }
};

const appendTrack = (track, index) => {
    u('#playlist').append(`
    <label class="stack">
      <input name="playlist" type="radio">
      <span class="button toggle track" id="_${track.id}" data-index="${index}">
        ${track.title}
        ${track.tags.slice(0, 3).map(tag =>
            '<span class="label tag">' + tag + '</span>').join('')}
      </span>
    </label>
    `);
};

const shareButtons = () => {
    return (`
    <div class="socialshares" data-theme="light-monotone" data-icononly>Share:
      <div class="socialshares-twitter" data-tooltip="Twitter"></div>
      <div class="socialshares-facebook" data-tooltip="Facebook"></div>
      <div class="socialshares-reddit" data-tooltip="Reddit"></div>
      <div class="socialshares-slack" data-tooltip="Slack"></div>
      <div class="socialshares-email" data-tooltip="Email"></div>
      <div class="socialshares-more" data-tooltip="More!"></div>
    </div>
    `);
};

// TODO window.origin

// Triggers with back/forward button?
window.onpopstate = e => {
    // TODO test more, handle /about/ and maybe others?
    play(u('#_' + e.state.id).data('index'), false);
};

// Allow esc to close modal
document.onkeydown = e => {
    if (e.keyCode == 27) {
        var mods = document.querySelectorAll('.modal > [type=checkbox]');
        [].forEach.call(mods, function(mod) {
            mod.checked = false;
        });
    }
};

// Minimal "analytics" logging
const bole = (initialLoad=false) => {
    fetch('/.netlify/functions/bole?documentReferrer=' + document.referrer +
          '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone +
          '&initialLoad=' + initialLoad + '&winHeight=' + window.innerHeight +
          '&winWidth=' + window.innerWidth + '&screenHeight=' + screen.height +
          '&screenWidth=' + screen.width);
};

// AJAX submission for contact form
setTimeout(() => {
    u('#contact-form').handle('submit', e => {
        const body = new FormData(e.target);
        fetch(u(e.target).attr('action'),
              { method: 'POST', body: body }).then(res => {
                  u('#modal-contact').first().checked = false;
                  alert('Thank you! Your message has been sent.');  // TODO info
              });
    });
}, 2000);  // TODO onpageload? Want it after Netlify "magic"

setup();
bole(true);  // Log initial page load
